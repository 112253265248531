<template>
  <div class="container-fluid">
    <de-statistic />
    <div class="pt-3 pb-5">
      <div class="bg">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des demandeurs</h4>
          </div>
          <form class="d-flex my-1">
            <div class="input-group me-4">
              <input v-model="mxSearch " type="text" class="form-control" placeholder="" aria-label="Example text with button addon" aria-describedby="button-query">
              <!-- <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Separated link</a></li>
              </ul> -->
              <button type="submit" class="style_btn btn btn-primary"  id="button-query"><i class="bi bi-search"></i></button>
            </div>
          </form>
          <a @click.prevent="$router.push({ name: 'espace.ce.de.add' })" href="" class="style_btn btn btn-warning">Inscrire un PPI</a>
          <!-- <div class="dropdown">
            <button class="btn style_btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Affectation</button>
            <ul class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton1" style="margin: 0px;">
              <li class="border">
                <a class="dropdown-item" @click="show" href="#">Affecter à des conseillers</a>
              </li>
              <li class="border">
                <a class="dropdown-item" @click="show" href="#">Affecter vers une antenne</a>
              </li>
            </ul>
          </div> -->
          <div class="btn-group ms-4">
            <button data-bs-toggle="tooltip" data-bs-placement="top" title="Affichage mode tableau" @click.prevent="viewMode='card'" class="btn style_btn btn-md" :class="{ 'btn-success': viewMode == 'card', 'btn-light': viewMode == 'table' }">
              <i class="bi-grid"/>
            </button>
            <button @click.prevent="viewMode='table'" class="btn style_btn" :class="{ 'btn-light': viewMode == 'card', 'btn-success': viewMode == 'table' }" >
              <i class="bi-table"/>
            </button>
          </div>
        </div>

        <!-- :paginate-elements-by-height="1400" -->
        <!-- :filename="'Fiche_'+de.nom+'_'+de.prenom+'.pdf'" -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :filename="'Fiche_azoli.pdf'"
            :pdf-quality="4"
            :manual-pagination="false"
            pdf-format="a4"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <!-- PDF Content Here -->
                <!-- <demandeur-detail  :demandeur="activeDemandeur"/> -->
                <fiche-azoli-report :demandeur="activeDemandeur" />
            </section>
        </vue-html2pdf>
        <!-- <DemandeurEdit ref="demandeurEditor" /> -->
        <div  v-if="mxLoading" class="d-flex justify-content-center pb-4" style="min-height: 10vh">
          <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="row mb-4" v-if="viewMode=='card'">
          <div class="col-sm-4 col-lg-3 mb-3" v-for="de in mxFilteredItems" :key="de.id">
            <de-card v-if="false" />
            <div class="style_profil_card p-3 pt-3"   >
              <div  @click.stop="showDemandeur(de)"  class="d-flex justify-content-center mb-3" v-if="de.azoli">
                <azoli-status-badge :de="de" />
              </div>
              <div class="mx-auto">
                <a href="#">
                  <div @click="uploadFile(de)" class="rounded-circle style_bg_img border" :style="`background-image: url(${de.fullPhotoUrl != null? de.fullPhotoUrl : '/assets/img/profilMan.jpg'}); height: 100px; width: 100px;`">
                  </div>
                  <!-- <file-pond
                      name="file"
                      v-if="false"
                      :ref="'pond'+de.id"
                      stylePanelLayout="circle"
                      accepted-file-types="image/jpeg, image/png"
                      :server="fileApiUrl"
                      :files="myFiles"
                      @init="handleFilePondInit"
                    /> -->
                </a>
              </div>
              <div class="py-3 text-center border-bottom">
                <div class="d-flex no-wrap justify-content-center align-items-center text-muted">
                    <!-- <small class="mt-1">4,5 | 7 avis</small> -->
                </div>
                <h6 class="my-1"><a href="#" class="text-dark text-uppercase">{{de.nom}} {{de.prenom}}</a></h6>
                <small v-if="de.creePar" class="text-muted">Réalisé par: {{ de.creePar.nom }} <span class="text-uppercase">{{ de.creePar.nom }}, le {{ de.lastLogin | formatDate }}</span></small>
                <small v-else class="text-muted">Réalisé par: <span class="text-bold">LUI-MEME</span></small>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <a class="btn btn-success rounded-0 btn-sm me-1" ><i class="bi bi-pencil-square"></i></a>
                <a class="btn btn-info rounded-0 btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Consulter et modifier"  @click.stop="showDemandeur(de)" ><i class="bi bi-info-square"></i></a>
                <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                <a class="btn btn-danger rounded-0 btn-sm me-1" title="Générer PDF" @click.stop.prevent="downloadFiche(de)"><i class="bi bi-printer"></i></a>
                <a @click.stop.prevent="remplirFiche(de)" title="Saisir la fiche" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-file-earmark-check"></i></a>
              </div>
          </div>
          </div>
          <div class="col-sm-12">
            <div class="d-flex flex-wrap justify-content-center align-items-center mt-3">
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
      </div>
      <div v-else class="bg-white p-3 border">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields" class="align-middle"
              striped
                select-mode="multi"
              ref="table"
              selectable
              @row-selected="onRowSelected"
              :items="tableData">
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(statut)="{ item: de }">
                <div class="d-flex justify-content-center mb-3" v-if="de.azoli">
                  <azoli-status-badge :de="de" />
                </div>
              </template>
              <template #cell(nom)="{ item: de }">
                <span class="text-uppercase">{{ de.nom }}</span>
              </template>
              <template #cell(prenom)="{ item: de }">
                <span class="text-uppercase">{{ de.prenom }}</span>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                    <a class="btn btn-success rounded-0 btn-sm me-1" title><i class="bi bi-pencil-square"></i></a>
                    <a class="btn btn-info rounded-0 btn-sm me-1" title="Consulter et modifier"  @click.stop="showDemandeur(data.item)" ><i class="bi bi-info-square"></i></a>
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                    <a class="btn btn-danger rounded-0 btn-sm me-1" title="Générer PDF" @click.stop.prevent="downloadFiche(data.item)"><i class="bi bi-printer"></i></a>
                    <a @click.stop.prevent="remplirFiche(data.item)" title="Saisir la fiche" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-file-earmark-check"></i></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>

         
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>Affichage de l’élément {{ mxPagination.page *mxPagination.rowsPerPage }} à {{ (mxPagination.page + 1)*mxPagination.rowsPerPage }} de {{ mxTotalCount }} éléments</p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div class="mb-4 bg-light" v-if=" !mxLoading && mxFilteredItems.length == 0">
        <div style="min-height: 200px" class="no-content pa-4 d-flex flex-column justify-content-center align-items-center">
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a @click.prevent="$router.push({ name: 'espace.ce.de.add' })" href="" class="style_btn btn btn-sm btn-outline-primary">Ajouter un PPI</a>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { mapActions, mapGetters } from 'vuex'
// import DemandeurEdit from '@/components/espace/admin/demandeur/DemandeurEdit'
import { paginatorMixin } from '@/mixins/paginator-mixin'
import DeStatistic from '../../../components/espace/demandeur/statistic/DeStatistic.vue'
import FicheAzoliReport from '../../../components/report/FicheAzoliReport.vue'
// import { FileService } from '../../../api/file-service'
import AzoliStatusBadge from '../../../components/common/AzoliStatusBadge.vue'

// Import Vue FilePond
// import vueFilePond from "vue-filepond"

// Import FilePond styles
// import "filepond/dist/filepond.min.css"

// Import Vue FilePond
// Import image preview and file type validation plugins
// import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
// import FilePondPluginImagePreview from "filepond-plugin-image-preview"


// const FilePond = vueFilePond(
//   FilePondPluginFileValidateType,
//   FilePondPluginImagePreview
// )

import { FILE_UPLOAD_API_URL } from '../../../config/api-config'
import DeCard from '../../../components/DeCard.vue'

export default {
  components: {
    DeStatistic,
    FicheAzoliReport,
    // FilePond,
    AzoliStatusBadge,
    DeCard
    // DemandeurEdit
  },
  mixins: [paginatorMixin],
  data () {
    return {
      myFiles: [],
      selected: [],
      search: '',
      viewMode: 'card', //card, table
      filterOptions: [
        // {
        //   column: 'departement',
        //   value: '',
        //   label: 'Département',
        //   items: []
        // },
        // {
        //   column: 'commune',
        //   value: '', 
        //   label: 'Commune',
        //   items: []
        // },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        // _queryOptions: {
        //   departement: 'departement',
        //   skills: 'skills__in',
        //   languages: 'languages__in',
        //   availabilities: 'availability__in',
        //   level: 'level',
        //   price_min: 'budget__gte',
        //   price_max: 'budget__lte'
        // },
        _queryOptions: [
          {
            column: 'departement',
            value: null,
            label: 'Département'
          },
          {
            column: 'commune',
            value: null, 
            label: 'Commune'
          },
        ],
        ordering: ''
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },  
        {
          text: 'Paramètres',
          href: '#'
        },
        {
          text: 'Demandeurs',
          active: true
        }
      ]
    }
  },
  created () {
    this.mxInitializePaginator({
      queryModel: 'demandeur',
      search: this.search,
      fetcherMethod: 'demandeur/fetchDemandeurs',
      dataGetter: 'demandeur/demandeurs',
      paginationGetter: 'demandeur/pagination',
      pagination: {
        sortBy: 'id',
        descending: false,
        page: 0,
        rowsPerPage: 20,
        sortingParam: `sort=id,desc`
      },
      filterOptions: this.filterOptions,
      searchFields: [ 'nom', 'prenom' ]
    })
  },
  watch: {
    dossier(val){
      if(val.azoli){
        this.activeDemandeur = {
          ...val,
          ...val.azoli
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'demandeur/demandeur',
      // demandeurs: 'demandeur/demandeurs',
      pagination: 'demandeur/pagination'
    }),
    tableFields () {
      return [
        'index',
        { key: 'matricule', label: 'Matricule' },
        { key: 'nom', label: 'Nom' },
        { key: 'prenom', label: 'Prénom' },
        { key: 'sexe', label: 'Sexe' },
        { key: 'phone', label: 'Téléphone' },
        { key: 'commune.nom', label: 'Commune' },
        { key: 'statut', label: 'statut' },
        'actions',
      ]
    },
    tableData () {
      return this.mxFilteredItems.map(item => {
        return { ...item }
      })
    },
    sexeOptions () {
      return ['M', 'F'].map(item => ({ value: item, text: item }) )
    },
    fileApiUrl(){
      return FILE_UPLOAD_API_URL
    }
  },
  methods: {
    ...mapActions({
      fetchDemandeurs: 'demandeur/fetchDemandeurs',
      createOrUpdateDemandeur: 'demandeur/createOrUpdateDemandeur',
      deleteDemandeur: 'demandeur/deleteDemandeur',
      downloadAzoli: 'demandeur/downloadAzoli',
      fetchDossier: 'demandeur/fetchDossier'
    }),
    onRowSelected(items) {
      this.selected = items
    },
    selectAll(evt){
      if(evt.target.checked){
        this.$refs.table.selectAllRows()
      }
      else {
        this.$refs.table.clearSelected()
      }
    },
    handleFilePondInit() {
      console.log("FilePond has initialized");
    },
    uploadFile(de){
      console.log(de)
      this.$refs[`pond2`].click()
    },
    onProgress(e){
      console.log(e)
    },
    downloadFiche(de){
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id
      }).then(() => {
        this.$refs.html2Pdf.generatePdf()
      })
    },
    editDemandeur (demandeur) {
      console.log(demandeur)
      this.activeDemandeur = { ...demandeur }
      this.$router.push({ name: 'espace.ce.de.edit' })
    },
    showDemandeur(demandeur) {
      console.log(demandeur)
      this.activeDemandeur = { ...demandeur }
      this.$router.push({ name: 'espace.ce.de.detail', params: { demandeurId: demandeur.id }})
    },
    remplirFiche(demandeur){
      this.$router.push({ name: "espace.ce.azoli.edit" , params: { demandeurId: demandeur.id } })
    },
    async destroyDemandeur (demandeur) {
      console.log(demandeur)
      await this.$confirm.require({
        group: 'confirmDialog',
        message: 'Voulez-vous vraiment supprimer cet élément?',
        header: 'Transfert non autorisé',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteDemandeur(demandeur)
        },
      });
    },
    updateOrCreateDemandeur (demandeur) {
      console.log(demandeur)
      this.createOrUpdateDemandeur(demandeur).then(data => {
        console.log(data)
        this.$toast.success("Opération réussie!", {
            position: 'bottom-right',
            duration: 5000
          })
      })
    }
  }
}
</script>

<style>

</style>